<template>
  <div class="">
    <v-layout row wrap class="">
      <v-flex xs12 md12 lg12 class="pa-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link> / Schedules
        </h1>
      </v-flex>
      <v-flex class="pa-5">
        <v-btn @click="filter = !filter" :class="getFilterButtonClass"
          ><v-icon class="mr-2">mdi-tune</v-icon>Filters</v-btn
        >
      </v-flex>
      <v-flex xs12 class="pa-5 d-flex" v-if="filter">
        <div >
          <div class="d-flex gap-2">
            <div class="pr-2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <p class="pa-0 ma-0">Date Range</p>
                  <v-text-field
                    v-model="dates"
                    single-line
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  range
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
              <!-- <v-date-picker v-model="dates" range></v-date-picker>
          <div class="grey darken-3 mt-3 pa-2" style="border-radius: 3px">
            {{ dates }}
          </div> -->
            </div>
            <div class="pl-2 pr-2">
              <p class="pa-0 ma-0">Duration</p>
              <v-select
                :items="durations"
                label="Duration"
                single-line
                outlined
                v-model="duration"
              ></v-select>
            </div>
            <div class="pl-2 pr-2">
              <p class="pa-0 ma-0">Day / Night</p>
              <v-select
                :items="types"
                label="Type"
                single-line
                outlined
                v-model="type"
              ></v-select>
            </div>
            <div class="pl-2 pr-2">
              <p class="pa-0 ma-0">Start Time</p>
              <v-text-field
                single-line
                outlined
                v-model="startTime"
                label="Ex. 1020"
              ></v-text-field>
            </div>
            <div class="pl-2 pr-2">
              <p class="pa-0 ma-0">End Time</p>
              <v-text-field
                single-line
                outlined
                v-model="endTime"
                label="Ex. 1250"
              ></v-text-field>
            </div>
          </div>
          <div class="pr-2 pt-5">
            <v-btn class="primary black--text" x-large @click="fetchSchedules"
              >Filter</v-btn
            >
            <v-btn x-large @click="clearFilters" class="ml-5">Clear</v-btn>
          </div>
        </div>
        <div class="pl-5 pr-5"></div>
      </v-flex>
      <v-flex xs12 md12 class="pa-5">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase primary--text">
                  Ac Unique Code
                </th>
                <th class="text-left uppercase primary--text">date</th>
                <th class="text-left uppercase primary--text">time</th>
                <th class="text-left uppercase primary--text">prepare</th>
                <th class="text-left uppercase primary--text">Fueling</th>
                <th class="text-left uppercase primary--text">Break</th>
                <th class="text-left uppercase primary--text">Take off</th>
                <th class="text-left uppercase primary--text">Duration</th>
                <th class="text-left uppercase primary--text">Etd</th>
                <th class="text-left uppercase primary--text">Landing time</th>
                <th class="text-left uppercase primary--text">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in schedules" :key="item._id">
                <td>{{ item.ac_unique_code }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.prepare }}</td>
                <td>
                  <span v-if="item.fueling">{{ item.fueling }}</span
                  ><span v-else>--</span>
                </td>
                <td>
                  <span v-if="item.break">{{ item.break }}</span
                  ><span v-else>--</span>
                </td>
                <td>{{ item.flight_take_off }}</td>
                <td>{{ item.duration }}</td>
                <td>{{ item.etd }}</td>
                <td>{{ item.landing_time }}</td>
                <td>{{ item.net_amount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { BASE_URL } from "../../config";
import Axios from "axios";
export default {
  data() {
    return {
      menu2: false,
      filter: false,
      schedules: [],
      loading: false,
      durations: ["no filter", 12, 15, 18],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      duration: "no filter",
      type: "no filter",
      types: ["no filter", "day", "night"],
      startTime: "",
      endTime: "",
      seats: "",
    };
  },
  mounted() {
    this.fetchSchedules();
  },
  computed: {
    getFilterButtonClass() {
      if (this.filter) {
        return "primary black--text";
      } else {
      }
    },
  },
  methods: {
    async fetchSchedules() {
      var q_params = "?";
      if (this.dates.length == 1) {
        q_params += "date=" + this.dates[0];
      } else {
        var _d = this.getStartEndDate();
        q_params += "startDate=" + _d.startDate + "&endDate=" + _d.endDate;
      }
      if (this.startTime && this.endTime) {
        q_params += "&startTime=" + this.startTime + "&endTime=" + this.endTime;
      }
      if (this.duration != "no filter") {
        q_params += "&duration=" + this.duration;
      }
      if (this.type != "no filter") {
        q_params += "&type=" + this.type;
      }
      this.$store.dispatch("SET_PROGRESS", true);
      let url = BASE_URL + "/schedule" + q_params;
      let { data } = await Axios.get(url);
      this.schedules = data.schedules;
      this.$toastr.s("Apply filters to tweak your data", "SCHEDULES LOADED");
      this.$store.dispatch("SET_PROGRESS", false);
    },
    getStartEndDate() {
      var date1 = new Date(this.dates[0]);
      var date2 = new Date(this.dates[1]);
      var startDate = date1 < date2 ? date1 : date2;
      var endDate = date1 < date2 ? date2 : date1;
      return {
        startDate: startDate.toISOString().split("T")[0], // Format as 'YYYY-MM-DD'
        endDate: endDate.toISOString().split("T")[0], // Format as 'YYYY-MM-DD'
      };
    },
    clearFilters() {
      (this.dates = [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ]),
        (this.duration = "no filter");
      this.type = "no filter";
      this.startTime = "";
      this.endTime = "";
      this.$toastr.s("Filters reset", "CLEARED");
      this.fetchSchedules();
    },
  },
};
</script>